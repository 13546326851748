import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { NavigationService } from '../../services/navigation-service';


@Component({
  selector: 'app-diffs-manufactured',
  templateUrl: './diffs-manufactured.component.html',
  styleUrls: ['./diffs-manufactured.component.scss'],
})
export class DiffsManufacturedComponent implements OnInit {
  constructor(
    private meta: Meta,
    private title: Title,
    private location: Location,
    private navigation: NavigationService
  ) {}

  back(): void {
    this.navigation.back();
  }

  ngOnInit(): void {
    this.title.setTitle(
      'Differences - Simple Comparision between Mobile, Manufactured, Modular and conventional or stick built homes.  See how quality factory built housing by Marlette compares'
    );

    this.meta.addTags([
      {
        name: 'description',
        content:
          'Simple Comparision between Mobile, Manufactured, Modular and conventional or stick built homes.  See how quality factory built housing by Marlette compares',
      },
    ]);
  }
}
