import { FloorplanService } from './../services/floorplan.service';
import { Component, NgZone } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { EMPTY } from 'rxjs';
import { NavigationService } from '../services/navigation-service';
import { Meta, Title } from '@angular/platform-browser';
import { Floorplan } from '../models/floorplan';
import { ViewportScroller, Location } from '@angular/common'; // Import ViewportScroller

@Component({
  selector: 'app-floorplan',
  templateUrl: './floorplan.component.html',
  styleUrls: ['./floorplan.component.scss'],
})
export class FloorplanComponent {
  //following reactive approach
  //https://stackoverflow.com/questions/74104530/how-can-i-get-an-object-by-id-using-observables-declarative-pattern
  //preferred pattern does not need an subscribe / unsubscribe when I use async pipe in html

  fp: Floorplan;
  private scrollPosition: [number, number]; // To store scroll position
  private id$; // Declare id$ without initialization
  public floorplan$; // Declare floorplan$ without initialization

  constructor(
    private route: ActivatedRoute,
    private floorplanService: FloorplanService,
    private navigation: NavigationService,
    private Title: Title,
    private Meta: Meta,
    private viewportScroller: ViewportScroller, // Inject ViewportScroller
    private location: Location,
    private router: Router,
    private zone: NgZone
  ) {
    this.id$ = this.route.paramMap.pipe(
      //gets the floorplanId from paramaters and returns observable
      map((params) => params.get('floorplanId'))
    );

    this.floorplan$ = this.id$.pipe(
      //emits floorplan as observable
      switchMap((id) => this.fetchFloorplan(id))
    );
  }

  private fetchFloorplan(id) {
    return this.floorplanService.getFloorplan(id).pipe(
      //tap((data) => console.log(JSON.stringify(data))),
      catchError((err) => {
        console.log(`Unable to get data for floorplan ${id}.`);
        return EMPTY;
      })
    );
  }

  onClose(): void {
    this.location.back();   //see app.config.ts which explains how we maintain scroll position prior to showing floorplan detail
  }

  ngOnInit() {
    // this.Title.setTitle(
    //   'Floorplan layout with details for Majestic, Schult, Summit Series, Columbia River or Tempo series of manufactured homes'
    // );
    // this.Meta.updateTag({
    //   name: 'description',
    //   content: 'showing floorplan and details',
    // });
  } // ngOnInit
}
