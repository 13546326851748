<div class="container-hero-pic">
  <img src="../../assets/Whats-New-Hero.jpg" alt="what new" />
  <div class="caption-hero-pic">Search All Homes</div>
</div>

<div class="on-display-title">
  Explore customizable single wide, double wide, and triple wide manufactured
  home floorplans at Heritage Home Center. Choose from many built-to-order
  options beyond our display models. Prices include delivery and setup, making
  your home move-in ready in less than 4 weeks. Free delivery anywhere in WA
  state. Heritage Homes offers exceptional value and personalized solutions for
  new manufactured homes.
</div>

<div class="format-filters">
  <div class="flex-section">  
    <div class="beds">
      <div class="staff_title">Select # of Bedrooms</div>

      <div class="label-select">
        <ng-container *ngFor="let option of bedroomOptions | keyvalue">
          <div class="label-checkbox-pair">
            <label [for]="'bedRoomOption' + option.key">
              {{ option.key }}
            </label>
            <input
              type="checkbox"
              class="checkbox-margin"
              (change)="filter()"
              [id]="'bedRoomOption' + option.key"
              [(ngModel)]="bedroomOptions[option.key]"
            />
          </div>
        </ng-container>
      </div>
    </div>

    <div class="baths">
      <div class="staff_title">Select # of Bathrooms</div>
      <div class="label-select">
        <ng-container *ngFor="let option of bathroomOptions | keyvalue">
          <div class="label-checkbox-pair">
            <label [for]="'bathRoomOption' | append : option.key">
              {{ option.key }}
            </label>
            <input
              type="checkbox"
              class="checkbox-margin"
              (change)="filter()"
              [id]="'bathRoomOption' + option.key"
              [(ngModel)]="bathroomOptions[option.key]"
            />
          </div>
        </ng-container>
      </div>
    </div>

    <div class="floors">
      <div class="staff_title">Select Width of Home</div>
      <div class="label-select">
        <ng-container *ngFor="let option of floorOptions | keyvalue">
          <div class="label-checkbox-pair">
            <label [for]="'floorOption' | append : option.key">{{
              option.key | floors
            }}</label>
            <input
              type="checkbox"
              class="checkbox-margin"
              (change)="filter()"
              [id]="'floorOption' + option.key"
              [(ngModel)]="floorOptions[option.key]"
            />
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- disable slider -->
  <!-- <div class="size">
    <label>Show me Homes smaller than (sq ft): </label>
    <mat-slider
      (change)="filter()"
      thumbLabel="true"
      [min]="minSqft"
      [max]="maxSqft"
      aria-label="units"
      [(ngModel)]="selectedSqft"
    >
    </mat-slider>
    <label>{{ selectedSqft }}</label>
  </div> -->
</div>

<mat-spinner *ngIf="isLoading; else floorPlanListings"></mat-spinner>

<ng-template #floorPlanListings>
  <div class="floorplanlist-container">
    <app-floorplan-box
      *ngFor="let floorplan of filteredHomes"
      [floorplan]="floorplan"
    >
    </app-floorplan-box>
  </div>
</ng-template>

<div class="on-display-title">
  <a routerLink="/display-models"> Show Me Homes On Display </a>
</div>
