import { Component, OnInit } from '@angular/core';
import { AppInsightsService } from './services/app-insights.service';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { Homepage2Component } from './components/homepage2/homepage2.component';

declare var gtag; //used below for google analytics

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  shouldShowFooter = true;
  desktopExperience = false;

  constructor(
    private title: Title,
    private meta: Meta,
    private appInsights: AppInsightsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    //for Google Analytics using gtag we get from the Google Console - see below
    const navEndEvents = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-ECJ2T348TP', {
        //from analytics console Tagging Instructions
        //page_path is the url the user has requested
        page_path: event.urlAfterRedirects,
      });
    });
  }

  ngOnInit() {
    //******************commented out since I set the title manually in each component for SEO */
    //SEO stuff.  description is set in index.html
    //this.setPageTitle(); //code pulled in from dev.to
    //******************commented out since I set the title manually in each component for SEO */

    this.title.setTitle(
      'Manufactured and Modular Homes Dealer selling singlewide, doublewide and triple wide mobile homes'
    );
    this.meta.addTags([
      {
        name: 'keywords',
        content:
          'Manufactured Home, Mobile Home, Modular Home,  Dealer, For Sale, Snohomish County, King County',
      },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Heritage Home Center' },
      { name: 'date', content: '2022-11-05', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
    ]);

    //use this to determine which navigation to show
    if (window.innerWidth > 800) {
      this.desktopExperience = true;
    }
  }

  // handleActivate(activatedComponent): void {
    // console.log('activatedComponent: ', activatedComponent);
    // this.shouldShowFooter = !(activatedComponent instanceof Homepage2Component);
  // }

  //code from from dev.to dynamically set page title in angular app
  private setPageTitle(): void {
    const defaultPageTitle = 'Manufactured Homes for sale';
    console.log('in app.component.ts');

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;

          if (!child) {
            return this.activatedRoute.snapshot.data.title || defaultPageTitle;
          }

          while (child.firstChild) {
            child = child.firstChild;
          }

          if (child.snapshot.data.title) {
            return child.snapshot.data.title || defaultPageTitle;
          }
        })
      )
      .subscribe((title: string) => {
        this.title.setTitle(title);
        console.log(title);
        this.appInsights.logPageView(title);
        this.appInsights.logEvent(title);
      });
  }
}
