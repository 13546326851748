<ul class="beautiful_display_homes">
  <li>Display Homes Unlocked Daily</li>
  <li>Knowledgeable Sales Here Everyday</li>
  <li>We Deliver Anywhere in Washington</li>
  <li>Many Upgrades, Zero Markup</li>
  <li>Custom Design Your Home</li>
</ul>

<div class="heritage_creates_more">
  Our inspiration? Guiding you to a home that's perfectly tailored, affordable,
  and move-in ready quickly. <br /><br />
  <a routerLink="/new" routerLinkActive="active"
    >Check Out Whats New, Updated Weekly</a
  >
</div>
