<div class="lets_build_your">
  <img src="../../../../assets/lets-build-your.png" alt="alt" />
  <div class="text">
    <p>
      Let’s build your DREAM home. Advantages of quality factory-built homes.
    </p>
    <a routerLink="/advantages" routerLinkActive="active"
      >Learn more about Manufactured Homes</a
    >
  </div>
</div>

<div class="lets_build_your">
  <img src="../../../../assets/curious-to-compare.png" alt="alt" />
  <div class="text">
    <p>Curious to compare manufactured homes with conventional housing?</p>
    <a routerLink="/diffs" routerLinkActive="active"
      >Comparison of Manufactured vs. conventional homes</a
    >
  </div>
</div>
