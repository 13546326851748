import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module';
import { FloorplanRoutingModule } from './floorplan-routing.module';
import { FloorplanComponent } from './floorplan.component';

@NgModule({
  declarations: [FloorplanComponent],
  imports: [CommonModule, FloorplanRoutingModule, MaterialModule, SharedModule],
})
export class FloorplanModule {}
