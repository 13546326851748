<div class="container-hero-pic">
  <img src="../../../../assets/Mask_Group_1@2x.jpg" alt="alt" />

  <div class="title_caption">
    Selling new Manufactured Homes in Washington State
    <div class="subtitle_caption">
      <br />Washington’s #1 Dealer for Singlewide, Doublewide & Triplewide
      Manufactured Homes – Serving the PNW and Alaska! Explore 10 furnished
      Display Models On-Site, Open Daily!

      <br />
      <br />
    </div>
  </div>
  <div class="view_this_model_home">
    <!-- <img src="../../../../assets/3D_Logo.png" alt="3D Logo" /> -->

    <!-- <a [routerLink]="" (click)="open3DTour()">
      Click here to see The Marlette Tempo Series
    </a> 
    commented out to simplify home page-->
    <div class="click_caption">
      Our passion is guiding you to the perfect home that you can enjoy for
      years to come. At Heritage Home Center, its always Ok to just look!
    </div>
  </div>
</div>
