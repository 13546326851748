import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobilenav',
  templateUrl: './mobilenav.component.html',
  styleUrls: ['./mobilenav.component.scss']
})
export class MobilenavComponent implements OnInit {
  title = 'Heritage Home Center';
  showNav = false;
  constructor() { }

  ngOnInit(): void {
  }

}
