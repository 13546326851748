import { Component, OnInit } from '@angular/core';
import { FloorplanService } from './../../../services/floorplan.service';
import { Floorplan } from '../../../models/floorplan';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-triplewide',
  templateUrl: './triplewide.component.html',
  styleUrls: ['./triplewide.component.scss']
})
export class TriplewideComponent implements OnInit {
  floorplans!: Floorplan[];
  filteredHomes!: Floorplan[];
  floorOptions: any;
  date = new Date();
  isLoading = false;

  constructor(
    private mobilehomeService: FloorplanService,
    private title: Title,
    private meta: Meta
  ) {}

  ngOnInit() {
    this.title.setTitle(
      'Triple Wide Manufactured Homes customized by Heritage Home Center'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'Luxurious and spacious - Triple Wide Mobile and Manufactured homes with customized choice of bedrooms, bathrooms and prices ranging from $200k for base models not including delivery',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Search Marlette Homes, Manufactured Home Floorplans, Filter for Bedrooms, Filter for Bathrooms',
    });

    this.isLoading = true;
    this.mobilehomeService.getFloorplans().subscribe((floorplans) => {
      this.floorOptions = Array.from(
        new Set(floorplans.map((home) => home.floors))
      )

        // .sort((a: number, b: number) => a - b)
        // .reduce((acc: any, item) => {
        //   acc[item] = false;
        //   return acc;
        // }, {});

      this.floorplans = floorplans;
      // this.filteredHomes = [...floorplans];
      this.filteredHomes = this.floorplans
      .filter(
        (home) =>
          home.floors == 3
      )

      this.isLoading = false;
    });

  } /** nginit */


  // filter() {

  //   console.log('this.floorOptions: ', this.floorOptions);

  //   /* follows pattern above */
  //   let selectedFloorOptions = Object.keys(this.floorOptions)
  //     .filter((key) => this.floorOptions[key])
  //     .map((item) => +item);
  //   if (selectedFloorOptions.length === 0) {
  //     selectedFloorOptions = Object.keys(this.floorOptions).map(
  //       (item) => +item
  //     );
  //   }

  //   this.filteredHomes = this.floorplans
  //     .filter(
  //       (home) =>
  //         selectedFloorOptions.includes(home.floors)
  //     )
  //     .sort(
  //       ({ size: sizeA }: Floorplan, { size: sizeB }: Floorplan) =>
  //         sizeA - sizeB
  //     );
  // } 


}
