import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'floors'
})
export class FloorsPipe implements PipeTransform {

  floorNumberToDislayLabelMap = {
    1: 'Singlewide',
    2: 'Doublewide',
    3: 'Triplewide',
    4: 'Quad',
  };

  transform(floors: number): string {
    return this.floorNumberToDislayLabelMap[floors];
  }

}
