<video
  src="https://hhcwebsitedocs.blob.core.windows.net/video/Tempo Singlewide.mp4"
  autoplay
  loop
  muted
  controls
>
  Your browser does not support the video tag.
</video>

<div class="floors">
  <!-- <div class="floor-label">Triplewide Manufactured Homes</div> -->
  <h1>
    Originally introduced in 2023, Tempo are a 2024 bestseller because of
    excellent pricing and included options.
  </h1>
</div>

<h2>What are Tempo manufactured homes?</h2>
<p>
  <b>Tempo brand</b> - introduced in 2023 as a new value series of homes our
  most requested options in a single base price. All <b>Tempo</b> homes come
  standard with Hardwood cabinets, Frigidaire stainless steel appliances,
  Craftsman style front door, Smart Thermostat plus others such as flat 9'
  ceilings and drywall throughout.
</p>

<h2>How much does the cheapest Tempo manufactured home cost?</h2>
<p>
  We can sell you a 1 bedroom, 1 bathroom 533 square foot Tempo singlewide for
  $55,000
  <b>plus</b> delivery to your site. Just call us and we can get you a firm
  quote to anywhere in Washington State.
</p>

<h2>
  Can I get financing for a either the singlewide or doublewide Tempo home?
</h2>
<p>
  Yes we have lenders who specialize in manufactured and can provide competitive
  financing as conventional mortgages and at competitive rates. Please call or
  write us for more info.
</p>

<h2>What is the largest single wide Tempo singlewide home?</h2>
<p>
  We can build you a 3 bedroom, 3 bathrom singlewide that is over 1,000 square
  feet. This can fit on may lots or park sites providing spacious living in a
  compact singlewide package for below $100,000 + delivery.
</p>

<h2>What else can I customize on a Tempo singlwide or doublewide?</h2>
<p>
  The base floor plans you see below come with standard and popular options. We
  can however work with you to customize some things such as colors and
  appliances along with a couple of other items. Your sales consultant can
  provide all the details, so please call for more information 425-353-5464.
</p>

<h2>How do you assemble the pieces of a single wide home?</h2>

<mat-spinner *ngIf="isLoading; else floorPlanListings"></mat-spinner>

<ng-template #floorPlanListings>
  <div class="floorplanlist-container">
    <app-floorplan-box
      *ngFor="let floorplan of filteredHomes"
      [floorplan]="floorplan"
    >
    </app-floorplan-box>
  </div>
</ng-template>

<div class="on-display-title">
  <a routerLink="/manufactured-home-singlewides"> Show Me all Single Wides </a>
</div>

<div class="on-display-title">
  <a routerLink="/manufactured-home-doublewides"> Show Me Double Wides </a>
</div>

<div class="on-display-title">
  <a routerLink="/manufactured-home-triplewides"> Show Me Triple Wides </a>
</div>

<div class="on-display-title">
  <a routerLink="/floorplans"> Show Me All Homes </a>
</div>
