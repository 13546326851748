import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-clearance',
  templateUrl: './clearance.component.html',
  styleUrls: ['./clearance.component.scss']
})
export class ClearanceComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    //SEO Enhancements
    this.title.setTitle(
      'Clearance 1 bedroom Manufactured Home | Heritage Home Center. '
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'This singlewide home is on clearance on dealer lot.  Come in to see.  Delivery is extra but dealer setup is included in this special priced home',
    });
  }

}
