<!-- <app-splashpage></app-splashpage> -->

<!-- toggle between  HomepageComponent and Homepage2Component by changing app-routing.module.ts -->
<!-- and find out what the class below does in index.html... messes up the new Homepage2Component formatting... found workaround to set line-height: normal in the global stylesheet
<body class="mat-typography">
  <app-root></app-root>
</body> -->

<!-- *******************************homepage1************************************** -->

<!-- <app-topnav *ngIf="desktopExperience; else showMobileExperience"></app-topnav>
<ng-template #showMobileExperience>
  <div class="mobilenav">
    <app-mobilenav></app-mobilenav>
  </div>
</ng-template>
<router-outlet (activate)="handleActivate($event)"></router-outlet>

<app-footer *ngIf="desktopExperience"></app-footer>  -->
<!-- only show footer on desktop UI until I unify the nav -->

<!-- *******************************homepage2************************************** -->

<app-topnavigation2
  *ngIf="desktopExperience; else showMobileExperience"
></app-topnavigation2>
<ng-template #showMobileExperience>
  <div class="mobilenav">
    <app-mobilenav></app-mobilenav>
  </div>
</ng-template>
<router-outlet></router-outlet>
<!-- <router-outlet (activate)="handleActivate($event)"></router-outlet> -->
<app-bottomnavigation2></app-bottomnavigation2>
<!-- we dont need the "only show footer on desktop UI check above" -->
