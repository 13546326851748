<header>
  <button (click)="showNav = !showNav" class="material-icons">
    {{ showNav ? "close" : "menu" }}
  </button>

  <!--
      The unusual handling of showNav ensures that the nav animations are only
      triggered at the appropriate points.
    -->
  <a routerLink="/" (click)="showNav = showNav ? false : showNav">
    {{ title }}
  </a>
</header>

<nav
  (click)="showNav = false"
  [class.showNav]="showNav"
  [class.hideNav]="showNav === false"
>
  <a routerLink="/home"> Home </a>
  <a routerLink="/display-models"> Display Center </a>
  <a routerLink="/floorplans"> All Homes </a>
  <a routerLink="/stories"> Stories </a>
  <a routerLink="/new"> What's New </a>
  <a routerLink="/aboutus"> About Us </a>
  <a routerLink="/findus"> Find Us </a>
  <a routerLink="/faq"> FAQ </a>
</nav>

<main>
  <router-outlet></router-outlet>
</main>


<!-- commented out since using the new footer called bottomnavigation2 -->
<!-- <footer>
  <a href="https://heritagehomecenter.com">Home</a>
  <a href="mailto:info@heritagehomecenter.com">Email</a>
  <a target="_blank" href="https://heritagehomecenter.com/">
    Call 425-353-5464
  </a>
</footer> -->
