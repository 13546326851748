<div class="container-hero-pic">
  <img
    src="https://hhcwebsitedocs.blob.core.windows.net/hero/triplewide-by-the-lake.jpg"
    alt="what new"
  />
  <div class="caption-hero-pic">We Build and Ship To Alaska</div>
</div>

<div class="looking">
  Looking for manufactured or mobile homes that are specifically built for
  Alaska? We know how to build homes for Alaska and can quickly turn an order
  around with the exact details you need, nothing more. We then ship the home
  from Seattle with the right care and attention to get it to you quickly and in
  one piece, ready for site installation. <br /><br />
  Ordering, building, and shipping your new home to Alaska is easy with our
  team. Contact us today to learn more about our selection of manufactured and
  mobile homes for Alaska. We have proven experience and customer references to
  share.
</div>

<h2>How expensive or difficult is new home construction in Alaska?</h2>

<p>
  New home construction in Alaska is expensive and time consuming. With a
  shorter building season, imported materials and a smaller labor pool, the per
  square foot cost of building in Alaska is at least 50% higher in Alaska. Get a
  quality Marlette Manufactured Home ordered, built and shipped within weeks.
  <br />
</p>

<h2>Why is Manufactured Housing a better option?</h2>

<p>
  Manufactured homes built in Washington State and then shipped by barge
  directly to you avoids all of the downsides of site building in Alaska.
  Climate is not a factor and building materials are sourced by a factory by the
  train load. Homes are built to standards acceptable to the State of Alaska
  with the right snow load and wind zone you need.
</p>

<h2>What do I need to know about homes for Alaska?</h2>

<p>
  Looking for a manufactured home that can withstand the harsh Alaskan winters?
  Look no further. Our manufactured homes are specially built to withstand
  shipping and the extreme weather conditions of Alaska. With an extra roof load
  of up to 120lbs per square foot (compared to the typical 20lbs roof load for
  other states), you can rest assured that your home will be sturdy and safe.
  <br /><br />We understand the unique requirements for building in Alaska and
  can take care of all the necessary details, including ensuring that your home
  is constructed to meet the right wind zone requirements. Our Energy Star
  certified homes are also designed to be energy-efficient, helping you save on
  heating costs. <br /><br />And for those cold winter nights, we now offer
  factory-installed wood stoves to keep your home warm and cozy. So why wait?
  Contact us today to learn more about our selection of manufactured homes built
  for Alaska.
</p>

<h2>How does Energy Star help me for Alaska built Manufactured Homes?</h2>

<p>
  Heritage Home Center are experts building Energy Star homes that are specially
  suited for Alaska weather. Stay warm in the winter and cool in the summer. See
  more information on our Energy Star homes.
  <a href="https://heritagehomecenter.com/energy-efficient-manufactured-homes"
    >Energy efficient manufactured homes</a
  >
</p>

<div class="office_pic_row_background">
  <div class="office_pic_row">
    <div class="it_is_our">
      Now available, factory installed wood stove to provide extra warmth during
      Alaska winters. Please call us for more information on this factory
      option.
    </div>

    <img
      src="https://hhcwebsitedocs.blob.core.windows.net/media/BlogPost-65-20221117-13529.jpg"
      class="office_pic"
      alt="Manufactured Home for Alaska shipment"
    />
  </div>
</div>

<h2>How much extra does it cost to build a home to Alaska building codes?</h2>

<p>
  The cost of contruction for Alaska standards is very reasonable for factory
  built manufactured. In total the extra cost is under $10,000 for a singlewide,
  excluding sea freight. Our pricing includes everything else including shipping
  FOB to the Port of Seattle.
</p>

<h2>Can you ship homes to Alaska?</h2>
<p>
  Of course! We will arrange to have the home inspected once built and prepared
  for a sea freight direct to your Alaska building site. The home is delivered
  to the pier and everything is included in the price. Call today for a price
  quote. We are open 7 days a week.
</p>

<h2>How do I find out more from the State of Alaska?</h2>
<p>
  The State provides streamlined application process and you can download
  <a href="https://doa.alaska.gov/dmv/forms/pdfs/870.pdf" target="_blank"
    >form</a
  >
</p>

<div class="office_pic_row_background">
  <div class="office_pic_row">
    <img
      src="https://hhcwebsitedocs.blob.core.windows.net/media/BlogPost-55-2022814-215652.jpg"
      class="office_pic"
      alt="Manufactured Home for Alaska shipment"
    />

    <div class="it_is_our">
      Single wide with covered windows and doors and prepared for shipment to
      Juneau Alaska by barge from Seattle pier. Our prices include free shipping
      to the pier in Seattle. These Alaska ready mobile or manufactured homes
      just make sense and get you moved in within weeks of shipping from
      Seattle. Let us help you build your Alaskan factory built home and get you
      moved in right away!
    </div>
  </div>
</div>
