<div class="dialog_close">
  <mat-dialog-actions>
    <button
      mat-icon-button
      class="close_button"
      color="accent"
      mat-dialog-close
      aria-label="Close Dialog"
      (click)="back()"
    >
      <mat-icon aria-hidden="false" aria-label="Close picture icon"
        >close</mat-icon
      >
    </button>
  </mat-dialog-actions>
</div>

<div class="office_pic_row_background">
  <div class="heritage_home_center">
    What is a Manufactured Home? <br />A manufactured home, also known as a
    mobile home, is a type of prefabricated home that is built off-site in a
    climate controlled factory and then transported to a customer site.
    Manufactured homes are constructed according to federal building codes and
    so are built to a consistent standard anywhere in the United States.
  </div>

  <div class="summary">
    <img
      src="../../../assets/pics/advantages.jpg"
      class="office_pic"
      alt="heritage home center office"
    />

    <div class="col_grid">
      <ul class="column1">
        <li>Speed of Construction</li>
        <li>Indoor Construction</li>
        <li>Manufacturing Scale</li>
        <li>Transport to Remote Locations</li>
      </ul>
      <ul class="column2">
        <li>Low Waste</li>
        <li>Flexibility</li>
        <li>High End Features</li>
        <li>Competitive Financing</li>
      </ul>
    </div>
  </div>

  <div class="heritage_home_center">
    What is the biggest advantage of a Manufactured Home?<br />
    Stick-built housing is expensive. The price tags for homes are high these
    days. The average price of a new-construction house in 2022 was nearly
    $600,000, according to the U.S. Census Bureau, and rising every year. Ouch!
  </div>

  <div class="heritage_home_center">
    The average sale price of a manufactured home, as of February 2023, was
    $128,000, according to the U.S. Census Bureau (exclusive of land cost or
    other expenses). Even the biggest, most deluxe models can be around
    $250,000.
  </div>
</div>

<div class="mysubtitle">
  Custom designed Manufactured Homes are a great alternative to other types of
  housing. With the high cost of new construction, as well as the time involved
  to get permits, a factory-built home just makes sense these days. Homes do not
  need a conventional building permits and because they are built in a factory,
  these homes are much lower cost and quicker to build. Enjoy the benefits of
  home ownership with much less hassle.
</div>

<div class="mysubtitle">
  Modern factory built homes, whether built to Federal HUD or Modular building
  codes are built on a chassis system that allows transport to the building site
  in components, often referred to as double wide for 2-section homes and triple
  wide for 3-section homes. Certified tradespeople setup and finish each home on
  your prepared site after the majority of the construction is previously
  completed. Homes can be set on a variety of approved foundation types,
  providing a beautiful high-end look that fits into any neighborhood.<br /><br />
</div>

<ul class="speed_of_construction">
  <li>
    Speed of Construction – off-site factory construction allows building and
    site work (such as grading, underground utilities etc) to be completed
    simultaneously, reducing overall completion since things move in parallel.
  </li>
  <li>
    Indoor Construction – Homes are constructed in a modern client controlled
    factory protected from weather, pests or other adverse site conditions. This
    results in work efficiencies and eliminates damaged building materials
    because of inclement weather.
  </li>
  <li>
    Manufacturing Scale - Marlette is a large-scale manufacturing plant that
    brings in building materials by the train load! Along with the national
    purchasing power of Clayton Homes, building hundreds of homes per year lets
    us get very very good at it.
  </li>
  <li>
    Remote Locations – homes built in a factory close to a large supply of
    materials and labor reduce both time and cost. Even the most remote cabin
    get-away can be built centrally and then moved to your site for final
    assembly and setup. In addition, your sales person can customize every aspec
    of the home as well as meet any specific site conditions (e.g. snow loads).
  </li>
  <li>
    Low Waste – popular floorplans are being constantly built, and so the
    manufacturer has records of exactly what quantity of materials are needed
    for a given job. While waste from a site-built dwelling may typically fill
    several large dumpsters, factory construction generates a lot less waste,
    reduces cost and, much better for the environment!
  </li>
  <li>
    Flexibility – we custom build every home to your exact specification, down
    to every light switch, wall tile to the roof truss. Start from a base
    floorplan and detail out each iNo two homes are alike.
  </li>
  <li>
    High End Features – solid wood frame floors and walls from high quality
    materials. Homes exteriors can also incorporate architectural features such
    as raised ceilings, large picture windows, beautiful gable roofs and 100's
    of other customization details while still keeping all the advantages of
    factory construction.
  </li>
  <li>
    Financing – manufactured homes can be financed like real property or
    combined with the land into a traditional mortgage. One advantage of keeping
    the finance separate is that it can often leave your land unencumbered by a
    mortgage if you use home-only financing. We work with great lenders that can
    give you some innovative options and competitive rates.
  </li>
</ul>

