<div class="delivering_top_rated">
  Our Washington State dealership has been recognized by Puget Sound Energy and
  Snohomish County PUD for our outstanding promotion and sales of Energy Star
  certified homes. As the leading dealer in the state, we are dedicated to
  providing energy-efficient options that benefit both our customers and the
  environment.
</div>

<h2>What rebate can I get for choosing an Energy Star Manufactured Home?</h2>
<p>
  If you select this zero-markup option when ordering your new home, you not
  only maximize energy savings but you also get a nice check. In 2024, this
  rebate is $2,200 payable directly to the homeowner after delivery. Your
  Heritage sales consultant will take care of all the paperwork.
</p>

<h2>What are the benefits of choosing Energy Star for my Manufactured Home?</h2>
<p>
  Lower Utility Bills and more comfort since your home will stay warmer in the
  winter and cooler in the summer. Marlette homes meet or exceed the Energy Star
  standard.
</p>

<h2>Are all Manufactured Home built to Energy Star standards?</h2>
<p>
  At Heritage Home Center, we are proud to offer Energy Star options for all our
  new manufactured homes, including singlewide, doublewide, and triplewide
  models. Our selection includes popular models such as the Tempo, Summit
  Series, and Canyon View series, which are manufactured by Marlette Homes and
  were introduced in 2023 for 2024 deliveries. When you order your home from us,
  you have the option to add Energy Star certification at factory cost (we can
  provide you with the invoice).
</p>

<div class="container2">
  <img
    src="../../../../assets/pics/energy-star-award.png"
    class="truck-pic"
    alt="heritage wins energy star award"
  />
</div>

<div class="description_text">
  Above picture: 2022 Award for dealer with the most rebated homes. Ask us how
  we can get you a rebate
</div>

<app-still-have-questions></app-still-have-questions>
