import { Component } from '@angular/core';
import { FloorplanService } from './../../../services/floorplan.service';
import { Floorplan } from '../../../models/floorplan';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tempo',
  templateUrl: './tempo.component.html',
  styleUrls: ['./tempo.component.scss'],
})
export class TempoComponent {
  floorplans!: Floorplan[];
  filteredHomes!: Floorplan[];
  floorOptions: any;
  date = new Date();
  isLoading = false;

  constructor(
    private mobilehomeService: FloorplanService,
    private title: Title,
    private meta: Meta
  ) {}

  ngOnInit() {
    this.title.setTitle(
      'Cheap Singlewide and Doublewide Manufactured Homes by Marlette on display at Heritage Home Center'
    );
    this.meta.updateTag({
      name: 'description',
      content: 'Floorplans for low priced Tempo singlewide and doublewide',
    });
    this.meta.updateTag({
      name: 'keywords',
      content: 'Smaller single wide homes by Tempo',
    });

    this.isLoading = true;
    this.mobilehomeService.getFloorplans().subscribe((floorplans) => {
      this.floorOptions = Array.from(
        new Set(floorplans.map((home) => home.floors))
      );

      // .sort((a: number, b: number) => a - b)
      // .reduce((acc: any, item) => {
      //   acc[item] = false;
      //   return acc;
      // }, {});

      this.floorplans = floorplans;
      // this.filteredHomes = [...floorplans];
      this.filteredHomes = this.floorplans.filter((home) => home.seriesName == "Tempo");

      this.isLoading = false;
    });
  } /** nginit */
}
