import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hero-pic',
  templateUrl: './hero-pic.component.html',
  styleUrls: ['./hero-pic.component.scss'],
})
export class HeroPicComponent implements OnInit {
  constructor(private router: Router) {}

  open3DTour() {
    // this.router.navigate(['/tours', "XF48ckpwg3m"]);
    this.router.navigate(['/tempo-by-Marlette']);
  }

  ngOnInit(): void {}
}
