import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-alaska',
  templateUrl: './alaska.component.html',
  styleUrls: ['./alaska.component.scss'],
})
export class AlaskaComponent implements OnInit {
  constructor(private title: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.title.setTitle(
      'Manufactured or Mobile Homes for Alaska Order, Build and Ship by Heritage Home Center'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'Get more information about quality Marlette manufactured homes that you can order and have shipped to Alaska saving you dollars and time vs other home options',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Search Marlette Homes, Manufactured Home Floorplans, Filter for Bedrooms, Filter for Bathrooms',
    });
  }
}
