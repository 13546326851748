import { FormsModule } from '@angular/forms';
import { AppendPipe } from '../pipes/append/append.pipe';
import { FloorsPipe } from './../pipes/floors/floors.pipe';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloorplansComponent } from './floorplans.component';
import { FloorplansRoutingModule } from './floorplans-routing.module';
import { RouterModule } from '@angular/router';
import { routes } from '../app-routing.module';

@NgModule({
  declarations: [FloorplansComponent, FloorsPipe, AppendPipe],
  imports: [CommonModule, FloorplansRoutingModule, SharedModule, FormsModule],
})
export class FloorplansModule {}
