import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-lets-build-row',
  templateUrl: './lets-build-row.component.html',
  styleUrls: ['./lets-build-row.component.scss']
})
export class LetsBuildRowComponent implements OnInit {

  constructor( private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {

    }
}
