import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homepage-intro',
  templateUrl: './homepage-intro.component.html',
  styleUrls: ['./homepage-intro.component.scss']
})
export class HomepageIntroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
