<div class="container-hero-pic">
  <img src="../../../assets/findus-hero-pic.jpg" alt="Find Us" />
  <div class="caption-hero-pic">Clearance $58,888 Singlewide Home</div>
</div>

<div class="on-display-title">
  $58,888 including setup (delivery quoted to your site at time of order) <br />
</div>

<div class="our_display_center">
  Marlette is building a new value line manufactured home and we are offering
  clearance pricing as an introductory special. This introductory clearance
  pricing is available on our lowest cost 1 bedroom, 1 bathroom singlewide
  offered at $58,888. Pricing includes setup to your prepared building site or
  mobile home park space. For this clearance home, delivery is available
  anywhere in Washington State and will be quoted at time of purchase.<br /><br />
  <b
    >Pricing is limited to this 1 bedroom , 1 bathroom singlewide as an
    introductory special. Home is ready for order and fast shipping.</b
  >
</div>
<div class="selling_points">
  <a href="https://heritagehomecenter.com/floorplans/94/Tempo/Imagine"
    >Click to see clearance floorplan</a
  >
</div>

<ul class="selling_points">
  <li>Full Drywall Throughout home</li>
  <li>Frigidaire Stainless Steel Appliance Package</li>
  <li>Craftsman Style Front Door</li>
  <li>Ecobee Smart Thermostat</li>
  <li>Carrier Furnace</li>
  <li>
    Quality Marlette build with 2" x 4" construction and 25-year roof warranty
  </li>
</ul>

<div class="selling_points">
  <a href="https://heritagehomecenter.com/tempo-by-Marlette">
    View all Tempo Homes</a
  >
</div>
