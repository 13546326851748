<div class="menu-bar-background">
  <div class="menu-bar">
    <a routerLink="/home" routerLinkActive="active">
      <img
        src="../../../assets/heritage_logo.png"
        class="logoH"
        alt="heritage graphic logo"
      />
    </a>
    <ul class="nav">
      <li>
        <a routerLink="/display-models" routerLinkActive="active"
          >DISPLAY HOMES</a
        >
      </li>
      <li>
        <a routerLink="/floorplans" routerLinkActive="active">ALL HOMES</a>
      </li>
      <li>
        <a routerLink="/stories" routerLinkActive="active">CUSTOMER STORIES</a>
      </li>
      <li><a routerLink="/new" routerLinkActive="active">WHAT'S NEW</a></li>
      <li><a routerLink="/aboutus" routerLinkActive="active">ABOUT US</a></li>
      <li><a routerLink="/findus" routerLinkActive="active">FIND US</a></li>
      <li><a routerLink="/faq" routerLinkActive="active">FAQ</a></li>
    </ul>
  </div>
</div>

<div class="chat_with_us_background">
  <div class="email_us_container">
    <div class="email_us">
      <a href="mailto: info@heritagehomecenter.com">
        <div class="email_us_text">
          <img
            src="../../../../assets/logos/email-envelope-logo.jpg"
            alt="email logo"
            width="30px"
          />
          Email Us
        </div>
      </a>
    </div>
    <div class="questions">
      <img
        src="../../../assets/questions.png"
        alt="questions?  call us 425-353-5464"
      />
    </div>
  </div>
</div>
