<div class="delivering_top_rated">
  Start Your Search Here
</div>

<div class="container2">
  <app-starthere2></app-starthere2>
  <div class="beautiful_display">
    <app-beautiful-display></app-beautiful-display>
  </div>
  <img
    src="../../../assets/truck.png"
    class="truck-pic"
    alt="heritage truck picture"
  />
</div>




<!-- <div class="container2">
  <img
    src="../../../assets/truck.png"
    class="truck-pic"
    alt="heritage truck picture"
  />
  <div class="beautiful_display">
    <app-beautiful-display></app-beautiful-display>
  </div>
  <app-starthere2></app-starthere2>
</div> -->
