import { Component, OnInit } from '@angular/core';
import { FloorplanService } from './../services/floorplan.service';
import { Floorplan } from '../models/floorplan';
import { Meta, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-floorplans',
  templateUrl: './floorplans.component.html',
  styleUrls: ['./floorplans.component.scss'],
})
export class FloorplansComponent implements OnInit {
  sub: Subscription;
  floorplans!: Floorplan[];
  filteredHomes!: Floorplan[];
  bedroomOptions: any;
  bathroomOptions: any;
  floorOptions: any;
  minSqft!: number;
  maxSqft!: number;
  selectedSqft!: number;
  date = new Date();
  isLoading: boolean;

  /* creates an array for bathroomOptions and bedroomOptions */
  /* e.g. this.bedroomOptions: {2: true, 3 :false, 4: false}
  sorted */
  constructor(
    private floorplanService: FloorplanService,
    private title: Title,
    private meta: Meta
  ) {}

  //Oct 21 2024 commented out line below for floorplan$ since was getting error on the floorplan.service
  //in floorplan.service also commented out the return of floorplan$
  //declarative pattern to replace ngOnInit, see floorplan0
  //need to take care of spinner first for declarative
  // floorplans$ = this.floorplanService.floorplans$;

  //prior to scully, need to change below getFloorplans to use the
  //  floorplans$ = this.http in floorplan service so that I replace the space with a '-'

  ngOnInit() {
    this.isLoading = true;
    this.sub = this.floorplanService.getFloorplans().subscribe((floorplans) => {
      //bathrooms array
      this.bathroomOptions = Array.from(
        new Set(floorplans.map((home) => home.bathrooms))
      )
        .sort((a, b) => a - b)
        // [1, 2, 3]
        .reduce((acc: any, item) => {
          acc[item] = false;
          return acc;
        }, {});

        console.log('Bathroom options:', this.bathroomOptions); // Add this line


      //bedrooms array
      this.bedroomOptions = Array.from(
        new Set(floorplans.map((home) => home.bedrooms))
      )
        .sort((a, b) => a - b)
        .reduce((acc: any, item) => {
          acc[item] = false;
          return acc;
        }, {});

      //floorplan options array
      this.floorOptions = Array.from(
        new Set(floorplans.map((home) => home.floors))
      )
        .sort((a: number, b: number) => a - b) //ascending order
        .reduce((acc: any, item) => {
          acc[item] = false;
          return acc;
        }, {});

      const availableSizes = Array.from(
        new Set(floorplans.map((home) => home.size))
      ).sort((a: number, b: number) => a - b); //ascending order
      this.minSqft = availableSizes[0];
      this.maxSqft = availableSizes[availableSizes.length - 1];
      this.selectedSqft = this.maxSqft;

      this.floorplans = floorplans;
      this.filteredHomes = [...floorplans];
      this.isLoading = false;
    });

    //SEO Enhancements
    for (let key in this.floorplans) {
      this.addMetaTag(
        'floorplan',
        'Marlette ' +
          this.floorplans[key].seriesName +
          ' ' +
          this.floorplans[key].modelName
      );
    }

    this.title.setTitle(
      'Search All Homes Majestic, Schult, Columbia River, Tempo Floor Plans select # of bedrooms, # bathrooms, and Singlewide, DoubleWide or TripleWide Manufactured Homes'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'Majestic, Schult and Columbia River, Tempo Floor Plans Floor Plans by Marlette Homes.  Floorplans can be customized for building site.  All homes are built to order and we are experts at helping you choose the right floorplan',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Search Marlette Homes, Manufactured Home Floorplans, Filter for Bedrooms, Filter for Bathrooms',
    });
  } /** nginit */

  ngOnDestroy(): void {
    console.log('floorplans component ngOnDestroy');
    this.sub.unsubscribe();
  }

  filter() {
    // console.log('this.bedroomOptions: ', this.bedroomOptions);
    // console.log('this.bathroomOptions: ', this.bathroomOptions);
    // console.log('this.floorOptions: ', this.floorOptions);

    let selectedBathroomOptions = Object.keys(
      this.bathroomOptions
    ) /* iterate over array above */
      .filter(
        (key) => this.bathroomOptions[key]
      ) /* keep only those keys (i.e option checked) where TRUE */
      .map(
        (item) => +item
      ); /* transforms string to numeric e.g. '2' becomes 2 as integer to match API */
    if (selectedBathroomOptions.length === 0) {
      /* if no option is selected, then no filter */
      selectedBathroomOptions = Object.keys(this.bathroomOptions).map(
        (item) => +item
      ); /* grab all the keys, i.e. no filter applied */
    }

    /* follows pattern above */
    let selectedBedroomOptions = Object.keys(this.bedroomOptions)
      .filter((key) => this.bedroomOptions[key])
      .map((item) => +item);
    if (selectedBedroomOptions.length === 0) {
      selectedBedroomOptions = Object.keys(this.bedroomOptions).map(
        (item) => +item
      );
    }

    /* follows pattern above */
    let selectedFloorOptions = Object.keys(this.floorOptions)
      .filter((key) => this.floorOptions[key])
      .map((item) => +item);
    if (selectedFloorOptions.length === 0) {
      selectedFloorOptions = Object.keys(this.floorOptions).map(
        (item) => +item
      );
    }

    this.filteredHomes = this.floorplans.filter(
      (home) =>
        selectedBedroomOptions.includes(home.bedrooms) &&
        selectedBathroomOptions.includes(home.bathrooms) &&
        selectedFloorOptions.includes(home.floors)
      // home.size >= this.minSqft &&
      // this.selectedSqft >= home.size
    );
    //commented out because causes confusing Ux after I filter and remove filter.  Improve w/ better sort from desktop
    // .sort(
    //   ({ size: sizeA }: Floorplan, { size: sizeB }: Floorplan) =>
    //     sizeA - sizeB
    // );
  } /** filter */

  addMetaTag(metaTagName: string, metaTagValue: string) {
    this.meta.addTag({ name: `${metaTagName}`, content: `${metaTagValue}` });
  }
}
