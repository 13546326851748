import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
  selector: 'app-advantages',
  templateUrl: './advantages.component.html',
  styleUrls: ['./advantages.component.scss'],
})
export class AdvantagesComponent implements OnInit {
  constructor(
    private meta: Meta,
    private title: Title,
    private location: Location
  ) {}

  back(): void {
    this.location.back();
  }

  ngOnInit(): void {
    this.title.setTitle(
      'Advantages of Manufactured Homes and pricing information'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'A manufactured home is a factory-built residence that can be placed on a piece of land. It costs much less than a traditional new build.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content: 'What is a manufactured home',
    });
  }
}
