<div class="menu-bar-background">
  <div class="menu-bar">
    <a routerLink="/home" routerLinkActive="active">
      <img
        src="../../../../assets/logos/logo-bottom.png"
        class="logoH"
        alt="heritage graphic logo"
      />
    </a>
    <ul class="nav">
      <li>
        <a routerLink="/display-models" routerLinkActive="active"
          >DISPLAY CENTER</a
        >
      </li>
      <li>
        <a routerLink="/floorplans" routerLinkActive="active">ALL HOMES</a>
      </li>
      <li>
        <a routerLink="/stories" routerLinkActive="active">CUSTOMER STORIES</a>
      </li>
      <li><a routerLink="/new" routerLinkActive="active">WHAT'S NEW</a></li>
      <li><a routerLink="/aboutus" routerLinkActive="active">ABOUT US</a></li>
      <li><a routerLink="/findus" routerLinkActive="active">FIND US</a></li>
      <li><a routerLink="/faq" routerLinkActive="active">FAQ</a></li>
    </ul>
  </div>
</div>
