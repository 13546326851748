<div class="floors">
  <!-- <div class="floor-label">Triplewide Manufactured Homes</div> -->
  <h1>Floor plans for Triple wide Manufactured Homes</h1>
</div>

<h2>What are triple wide manufactured homes?</h2>
<p>
  Triple wide homes come in 3 separate sections, built in a factory and shipped
  to your homesite. Marlette builds triple wides under the Majestic Brand -
  their top of the line series that includes many luxurious features as standard
  in the base model.
</p>

<h2>How much do triple wide homes cost?</h2>
<p>
  Because triple wides come in 3 sections, the cost to transport and construct
  on site is slightly higher than with a double wide which has 2 sections. Even
  so, the extra size and layout options you get from 3 sections, make sense as
  these are still cost effective and
  <b>1/3 of the cost to construct the same square footage on site!</b> In 2022
  the base cost excluding freight is as low as $230K finshed and move-in ready!
  Delivery cost is quoted separately depending on your address. We can give you
  a pretty accurate estimate over the phone.
</p>

<h2>How big are triple wide homes?</h2>
<p>
  Every floor plan order is custom built using a base floorplan and then you can
  configure the number of bedrooms and bathrooms ranging from 3 to 5 bedrooms
  and 2 to 3 bathrooms in any configuration and finish. We can custom build a
  triple wide from about 1,800 square feet to over 3,000 square feet. These open
  floor plans provide large living in a single floor.
</p>

<h2>Are these homes as good as a site or stick built homes</h2>
<p>
  All of our homes are built with quality. For example, outside walls are 2x6",
  inside walls are 2x4" and floor are 2x10" of grade-A lumber.
</p>

<h2>What else can I customize when I buy a triple wide home?</h2>
<p>
  The base floor plans you see below can be customized from basic to luxury with
  vaulted loft-style ceilings, large windows, cabinets, solid surface
  countertops, walk-in pantry, extra tall windows, glass tile finishes, roomy
  bathrooms with walk in showers, jacuzzi tubs, upgraded window treatments,
  floor coverings etc.
</p>

<h2>How do you assemble the pieces of a triple wide home?</h2>
<p>
  The 3-sections are joined starting with section A then B and C. In many cases,
  we the roof trusses come pre-shipped inside the home and then get assembled on
  site to create the tall roof lines that make our homes beautiful inside and
  out.
</p>

<mat-spinner *ngIf="isLoading; else floorPlanListings"></mat-spinner>

<ng-template #floorPlanListings>
  <div class="floorplanlist-container">
    <app-floorplan-box
      *ngFor="let floorplan of filteredHomes"
      [floorplan]="floorplan"
    >
    </app-floorplan-box>
  </div>
</ng-template>

<div class="on-display-title">
  <a routerLink="/manufactured-home-singlewides"> Show Me Single Wides </a>
</div>

<div class="on-display-title">
  <a routerLink="/manufactured-home-doublewides"> Show Me Double Wides </a>
</div>

<div class="on-display-title">
  <a routerLink="/floorplans"> Show Me All Homes </a>
</div>
