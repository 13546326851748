import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-energystar',
  templateUrl: './energystar.component.html',
  styleUrls: ['./energystar.component.scss'],
})
export class EnergystarComponent {
  constructor(private title: Title, private meta: Meta) {}

  ngOnInit() {
    this.title.setTitle('Energy Star option benefits for manufactured homes');
    this.meta.updateTag({
      name: 'description',
      content:
        'Tempo, Canyon View, Summit Series and Majestic, Schult and Columbia River, Canyon View and Summit Series Floor Plans by Marlette Homes.  Floorplans can be customized for building site.  All homes are built to order and we are experts at helping you choose the right floorplan',
    });
    this.meta.updateTag({
      name: 'keywords',
      content: 'Energy Star, Puget Sound Energy, Snohomish County PUD',
    });
  }
}
