<div class="floors">
  <!-- <div class="floor-label">Triplewide Manufactured Homes</div> -->
  <h1>New Value Priced Single Wide Homes by Marlette called Tempo</h1>
</div>

<h2>What are single wide manufactured homes?</h2>
<p>
  Single wide homes come in 1 section, built in a factory and shipped to your
  home site. Marlette also builds value priced single wides under the
  <b>Tempo brand</b> - providing value in a small package that fits into mobile
  home park spaces or a accessory dwellings, cottages etc on your property.
</p>

<h2>How much do single wide homes cost?</h2>
<p>
  Because single wides come in just one section, the cost to transport and
  construct on site is much lower than either with triple wide or double wides.
  Because both transport and finishing costs are reduced, these are very cost
  effective and a fraction of constructing something like this on your site.
  Leverage the scale of factory-built housing. Whether your new singlewide
  becomes your cottage, accessory dwelling, rental property, weekend getaway by
  the lake, or your primary residence these are great options. park.
</p>

<h2>Can I get financing for a single wide manufactured or mobile home?</h2>
<p>
  Yes we have lenders who specialize in manufactured and can provide competitive
  financing as conventional mortgages and at competitive rates. Please call or
  write us for more info.
</p>

<h2>What is the largest single wide manufactured / mobile home?</h2>
<p>
  We can build a
  <b>3 bedroom, 2 bathroom single wide that is nearly 1,100 square feet</b> with
  the master bedroom split on one side and 2 bedrooms on the other side. The
  main living area including kitchen, dining and utility rooms are in the middle
  of the home. This provides common living areas yet still offers privacy for
  the bedrooms.
</p>

<h2>What else can I customize when I buy a single wide home?</h2>
<p>
  The base floor plans you see below can be customized from basic to luxury with
  vaulted loft-style ceilings, large windows, cabinets, solid surface
  countertops, walk-in pantrys, extra tall windows, glass tile finishes, roomy
  bathrooms with walk-in showers, jacuzzi tubs, upgraded window treatments,
  floor coverings etc. The Tempo series comes standard with full drywall
  throughout the home, hardwood cabinets, Frigidaire stainless steel appliances
  and craftsman style front doors.
</p>

<h2>How do you assemble the pieces of a single wide home?</h2>
<p>
  No on-site assembly is required because the entire home is built in a single
  section and delivered to your prepared site, nearly move-in ready (except for
  carpet and paint)
</p>

<mat-spinner *ngIf="isLoading; else floorPlanListings"></mat-spinner>

<ng-template #floorPlanListings>
  <div class="floorplanlist-container">
    <app-floorplan-box
      *ngFor="let floorplan of filteredHomes"
      [floorplan]="floorplan"
    >
    </app-floorplan-box>
  </div>
</ng-template>

<div class="on-display-title">
  <a routerLink="/manufactured-home-doublewides"> Show Me Double Wides </a>
</div>

<div class="on-display-title">
  <a routerLink="/manufactured-home-triplewides"> Show Me Triple Wides </a>
</div>

<div class="on-display-title">
  <a routerLink="/floorplans"> Show Me All Homes </a>
</div>
