<div class="container1">
  <div class="each_home_is">
    Each home is customized and built to order just for you
  </div>
</div>

<div class="container2">
  <div class="col1">
    <img src="../../../../assets//logos/logo_sales.png" alt="sales logo" />

    <div class="subtitle">Factory Trained Sales People</div>

    <div class="blurb">
      Heritage Sales listens to what you want and then draws on years of
      experience to design your home within your budget. Each home is customized
      and built to order, from every light switch to every window covering. Get
      what you want, how you want it.
    </div>
  </div>
  <div class="col2">
    <img src="../../assets//logos/logo_service.png" alt="sales logo" />

    <div class="subtitle">In-House Service Team</div>

    <div class="blurb">
      Once your home is built, the Service Department will take over delivery
      and set it up. Service will take care of every detail to finish your home,
      and finish with a complete walkthrough to make sure everything is to your
      satisfaction and provide after sales warranty service.
    </div>
  </div>
  <div class="col3">
    <img src="../../assets//logos/logo_financing.png" alt="sales logo" />

    <div class="subtitle">Competitive Financing</div>

    <div class="blurb">
      We can recommend banks and lenders who go the extra mile to get you
      low-rate financing without extra fees. We have FHA, VA and conventional
      mortgages for home, land or combined. If you have a job, we can get you
      competitive financing. warranty service.
    </div>
  </div>
</div>
