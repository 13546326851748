import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'append',
})
export class AppendPipe implements PipeTransform {
  transform(stringValue: string, key: number): unknown {
    return `${stringValue} ${key}`;
  }
}
