import { Component, inject, OnInit } from '@angular/core';
import { FloorplanService } from './../../../services/floorplan.service';
import { Floorplan } from '../../../models/floorplan';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-singlewide',
  templateUrl: './singlewide.component.html',
  styleUrls: ['./singlewide.component.scss'],
})
export class SinglewideComponent implements OnInit {
  floorplans!: Floorplan[];
  filteredHomes!: Floorplan[];
  floorOptions: any;
  date = new Date();
  isLoading = false;

  constructor(
    private mobilehomeService: FloorplanService,
    private title: Title,
    private meta: Meta
  ) {}

  ngOnInit() {
    this.title.setTitle(
      'Single Wide Manufactured homes Tempo Imagine, Tempo Satisfaction, Tempo Yesterday, Tempo Layla, Tempo Respect, Tempo Still the One, Tempo Good Vibrations, Tempo Born to Run, Tempo Rhythm Nation, Tempo Move On Up'
    );
    this.meta.updateTag({
      name: 'description',
      content: 'Floorplans for smaller single section homes from Tempo',
    });
    this.meta.updateTag({
      name: 'keywords',
      content: 'Smaller single wide homes by Tempo',
    });

    this.isLoading = true;
    this.mobilehomeService.getFloorplans().subscribe((floorplans) => {
      this.floorOptions = Array.from(
        new Set(floorplans.map((home) => home.floors))
      );

      // .sort((a: number, b: number) => a - b)
      // .reduce((acc: any, item) => {
      //   acc[item] = false;
      //   return acc;
      // }, {});

      this.floorplans = floorplans;
      // this.filteredHomes = [...floorplans];
      this.filteredHomes = this.floorplans.filter((home) => home.floors == 1);

      this.isLoading = false;
    });
  } /** nginit */
}


