<!-- COMPARISION SECTION -->
<div class="dialog_close">
  <mat-dialog-actions>
    <button
      mat-icon-button
      class="close_button"
      color="accent"
      mat-dialog-close
      aria-label="Close Dialog"
      (click)="back()"
    >
      <mat-icon aria-hidden="false" aria-label="Close picture icon"
        >close</mat-icon
      >
    </button>
  </mat-dialog-actions>
</div>

<div class="intro-blurb">
  <span
    >A simple comparison between on the difference between
    <b>manufactured</b> and <b>modular</b> and <b>conventional stick</b> built
    residential construction.</span
  >
</div>
<div class="flex-even-cols">
  <div class="services-col">
    <div class="intro-blurb"><b>manufactured</b></div>
    Homes called Maufactured (as opposed to the old Mobile Homes) have one thing
    in common across the country - a single unified construction standard
    developed by the Federal Government. Like our Constitution, its so simple
    but also brilliant! It allows a builder located anywhere, from Washington to
    Texas to build a residental dwelling of any size exactly the same way using
    a common standard. Think about buying a common kitchen appliance like a
    toaster. Whether you plug that in Boise or Boca Raton, it will work exactly
    the same way. Why not build homes this way? Well Manufactured Homes are
    built to a Federal standard adopted by Congress in 1976. Homes built prior
    to this are called "Mobile Homes" and built to a lower standard of
    construction with inferior building materials. Thankfully those days are
    behind us and so today you can walk into a dealer anywhere in the United
    States and buy a manufactured home that will be like the proverbial toaster.
    Just plug it in and it works. Brilliant isnt it.
  </div>
  <div class="services-col">
    <div class="intro-blurb"><b>modular</b></div>
    Homes called Modular while sometimes confusingly also called Manufactured,
    may look similar to Manufactured Homes but they are built to a State level
    building code and not the Federal HUD standard. While still offering the
    same advantages of Manufactured Homes, these Modulars require extra time to
    build and get State approvals during each step of the build process. In
    Washington State, that means approvals from WA Department of Licensing.
    Furthermore, these homes need a perimeter foundation just like with stick
    built homes and follow the same types of permit approvals.
  </div>
  <div class="services-col">
    <div class="intro-blurb"><b>conventional stick</b></div>
    Homes are built on site with building materials using a general contractor.
    Site built homes offer the most flexibility in layout and construction
    materials, and are the right option for many prospective new home buyers.
    These homes can take much longer to get permits submitted and approved and
    also cost significantly more to construct on a building site. If your budget
    and timeline allow stick built, and you really want a custom design, these
    may be the right home for you.
  </div>
</div>

<!-- <iframe allowtransparency="true" title="Wistia video player" allowFullscreen frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" src="https://fast.wistia.net/embed/iframe/2c5k2n140c" width="400" height="225"></iframe> -->
